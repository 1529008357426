<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Form Tabungan</strong>
        <a-button
          size="default"
          type="danger"
          class="ml-2"
          @click="resetForm"
          >Back</a-button
        >
      </template>
      <div class="content">
        <div class="row pl-3 pr-3 pt-3 pb-3">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">Tanggal</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-date-picker
                  style="width: 130px;"
                  v-model="input.tgl"
                  :allowClear="false"
                  format="DD-MM-YYYY"
                />
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">No. Register</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input placeholder="" v-model="maskInput.cabang" style="width: 60px;" :readOnly="false"></a-input>
                <a-input class="ml-2" placeholder="" v-model="maskInput.reregister" style="width: 50%;" :readOnly="false" @keydown.enter="registeridchanged"></a-input>
                <a-button class="ml-2"
        @click="carinasabahvisible = !carinasabahvisible">
                  <a-icon type="search"></a-icon>
                </a-button>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">Nama Nasabah</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input placeholder="Nama Nasabah" v-model="maskInput.namanasabah" :readOnly="true"></a-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">Alamat Nasabah</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input placeholder="Alamat Nasabah" v-model="maskInput.alamatnasabah" :readOnly="true"></a-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">Telepon</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input placeholder="Telepon" v-model="maskInput.telepon" :readOnly="true"></a-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">AO</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="input.ao"
                  style="width: 70%;"
                >
                  <a-select-option
                    v-for="(data, index) in masterAO"
                    :key="index"
                    :value="data.kode"
                    >{{ data.kode + ' - ' +data.nama }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2" v-if="action !== 'create'">
                <label class="mt-1">Rekening</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2" v-if="action !== 'create'">
                <a-input placeholder="" v-model="maskInput.rekcabang" style="width: 60px;" :readOnly="false"></a-input>
                <a-input class="ml-2" placeholder="" v-model="maskInput.rerekening" style="width: 60px;" :readOnly="false"></a-input>
                <a-input class="ml-2" placeholder="" v-model="maskInput.rerekening1" style="width: 30%;" :readOnly="false" @keydown.enter="rekeningcheck"></a-input>
                <a-button class="ml-2" @click="carinasabahvisible = !carinasabahvisible">
                  <a-icon type="search"></a-icon>
                </a-button>
                <!-- <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="input.rekening"
                  style="width: 100%;"
                >
                  <a-select-option v-for="(data, index) in masterregisterNasabah" :value="data.kode" :key="index">{{ data.kode + ' - ' + data.nama }}</a-select-option>
                </a-select> -->
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">No Seri</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input
                  placeholder="Masukan No Seri"
                  v-model="input.noseri"
                ></a-input>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">

              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">Gol Tabungan</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="input.golongantabungan"
                  style="width: 100%;"
                  @change="golonganidchanged"
                >
                  <a-select-option v-for="(data, index) in mastergolonganTabungan" :value="data.kode" :key="index">{{ data.kode +' - '+ data.keterangan}}</a-select-option>
                </a-select>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">ZIS</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input placeholder="Masukan ZIS" v-model="input.zis"></a-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">Ahli Waris</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input
                  placeholder="Masukan Ahli Waris"
                  v-model="input.ahliwaris"
                ></a-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
                <label class="mt-1">Baris Terakhir</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
                <a-input
                  placeholder="Masukan Baris Terakhir"
                  v-model="input.baristerakhir"
                ></a-input>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                <a-button class="btn btn-outline-primary float-right" style="width: 100px;" @click="handleSubmit">Submit</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal
        v-model="carinasabahvisible"
        :dialogStyle="{ top: '10px' }"
        :footer="null"
        :width="800"
      >
        <template slot="title">
          <strong>{{ carinasabahtitle }} </strong>
          <label style="margin: unset;" v-if="settings.selectedRekening !== ''">
            | Active Rekening: {{ settings.selectedRekening }}</label
          >
        </template>
        <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
    <div class="row">
      <a-input class="ml-3" placeholder="Ketik Nama" v-model="inputModal.nama" style="width: 200px;" @keydown.enter="findData"></a-input>
      <a-input class="ml-2" placeholder="Ketik Alamat" v-model="inputModal.alamat" style="width: 200px;" @keydown.enter="findData"></a-input>
      <a-select style="width: 70px;" placeholder="Kode" class="ml-2" v-model="inputModal.cabang">
        <a-select-option value="01">01</a-select-option>
      </a-select>
      <a-button v-if="settings.selectedRekening !== ''" class="btn btn-outline-danger btn-sm ml-2" @click="clearRekening">Clear Selected Rekening</a-button>
      <a-divider type="horizontal" style="margin: 8px 8px;"/>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <label v-if="table.tableatas.datatable.length !== null && table.tableatas.datatable.length !== 0" class="text-primary" style="margin-left: .5rem; margin-top: .5rem; margin-bottom: unset !important;">Jumlah Data: {{ table.tableatas.datatable.length }}</label>
        <a-table
          :scroll="{ y: 300 }"
          :columns="table.tableatas.column"
          :dataSource="table.tableatas.datatable"
          :pagination="false"
          size="small"
          :customRow="customRow"
        >
        </a-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
        <label v-if="table.tablebawah.datatable.length !== null && table.tablebawah.datatable.length !== 0" class="text-primary" style="margin-left: .5rem; margin-top: .5rem; margin-bottom: unset !important;">Jumlah Data: {{ table.tablebawah.datatable.length }}</label>
        <a-table
          :scroll="{ y: 200 }"
          :columns="table.tablebawah.column"
          :dataSource="table.tablebawah.datatable"
          :pagination="false"
          size="small"
          :customRow="customRowB"
        >
          <span slot="saldo" slot-scope="text">
            {{ formatCurrency(text) }}
          </span>
        </a-table>
      </div>
    </div>
        <!-- <lou-cari-nasabah /> -->
      </a-modal>
  </div>
</template>

<script>
// import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
// import LouCariNasabah from './CariNasabah'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // LouCariNasabah,
  },
  watch: {
    'settings.selectedRekening'() {
      this.checkRekening()
    },
    'settings.selectedKodeRegister'() {
      this.input.register = this.settings.selectedKodeRegister
      this.maskInput.cabang = this.settings.selectedKodeRegister.slice(0, this.settings.selectedKodeRegister.indexOf('.'))
      this.maskInput.reregister = this.settings.selectedKodeRegister.slice(this.settings.selectedKodeRegister.indexOf('.') + 1, this.settings.selectedKodeRegister.length)
      this.registeridchanged()
    },
  },
  data() {
    return {
      editdata: JSON.parse(
        localStorage.getItem('newkoperasiedittabungandata'),
      ),
      action: null,
      title: 'Register Nasabah',
      fetching: false,
      loadingMaster: false,
      maskInput: {
        cabang: '01',
        rekcabang: '01',
        reregister: '',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
        noregister: '',
      },
      input: {
        rekening: '',
        tgl: moment(),
        golongantabungan: '',
        golongan_id: '',
        register: '',
        register_id: '',
        zis: 0,
        ao: '',
        ahliwaris: '',
        noseri: '',
        baristerakhir: 0,
      },
      mastergolonganTabungan: [],
      backupmastergolonganTabungan: [],
      masterregisterNasabah: [],
      backupmasterregisterNasabah: [],
      masterAO: [],
      backupmasterAO: [],
      visible: false,
      loading: false,
      datenow: '',
      componentKey: 0,

      // Modal Property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              // width: 100,
            },
          ],
          datatable: [],
        },
      },
    }
  },
  created() {
    this.getMaster()
    if (this.settings.selectedRekening !== '') {
      this.checkRekening()
    }
    if (this.settings.selectedKodeRegister !== '') {
      this.input.register = this.settings.selectedKodeRegister
      this.maskInput.cabang = this.settings.selectedKodeRegister.slice(0, this.settings.selectedKodeRegister.indexOf('.'))
      this.maskInput.reregister = this.settings.selectedKodeRegister.slice(this.settings.selectedKodeRegister.indexOf('.') + 1, this.settings.selectedKodeRegister.length)
      this.registeridchanged()
    }
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Nasabah'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Nasabah'
    }
    // console.log('this.action', this.action)
  },
  methods: {
    moment,
    async rekeningcheck() {
      var res = await lou.customUrlGet2('tabungan/rekening/' + this.maskInput.rekcabang + '.' + this.maskInput.rerekening + '.' + this.maskInput.rerekening1)
      if (res) {
        console.log('res', res)
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
        } else {
          lou.shownotif('Tidak ditemukan!', 'Rekening tidak ditemukan.', 'error')
        }
      }
    },
    async checkRekening() {
      this.input.rekening = this.settings.selectedRekening
      var text = this.settings.selectedRekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(text.indexOf('.') + 1, text.indexOf('.') + 3)
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(ntext.indexOf('.') + 1, ntext.length)
    },
    async registeridchanged() {
      var res = await lou.customUrlGet2('registernasabah/kode/' + this.maskInput.cabang + '.' + this.maskInput.reregister)
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Data register ditemukan.')

          // console.log('res.data', res.data)
          this.input.register_id = res.data.id
          this.input.register = res.data.kode
          this.maskInput.namanasabah = res.data.nama
          this.maskInput.alamatnasabah = res.data.alamat
          this.maskInput.telepon = res.data.hp
          this.maskInput.pekerjaan = res.data.pekerjaan
        } else {
          lou.shownotif('Tidak ditemukan!', 'Data register tidak ditemukan.', 'error')

          this.input.register_id = ''
          this.input.register = ''
          this.maskInput.namanasabah = ''
          this.maskInput.alamatnasabah = ''
          this.maskInput.telepon = ''
          this.maskInput.pekerjaan = ''
        }
      }
    },
    golonganidchanged(e) {
      var target = this.mastergolonganTabungan.findIndex(x => x.kode === e)
      if (target !== -1) {
        this.input.golongan_id = this.mastergolonganTabungan[target].id
        this.input.golongantabungan = this.mastergolonganTabungan[target].kode
      }
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    async getMaster() {
      var response1 = await lou.customUrlGet2('ao')
      if (response1) {
        this.masterAO = this.$g.clone(response1.data)
        this.backupmasterAO = this.$g.clone(response1.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
      this.getMasterRegister()
      var response = await lou.customUrlGet2('golongantabungan')
      if (response) {
        this.mastergolonganTabungan = this.$g.clone(response.data)
        this.backupmastergolonganTabungan = this.$g.clone(response.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
    },
    async getMasterRegister() {
      var response = await lou.customUrlGet2('registernasabah')
      if (response) {
        this.masterregisterNasabah = this.$g.clone(response.data)
        this.backupmasterregisterNasabah = this.$g.clone(response.data)
        // console.log('this.masterregisterNasabah', this.masterregisterNasabah)
      }
    },
    async getMasterKota() {
      var indexeddb = await lou.getDataFromDb()
      var masterKotaIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'masterKota' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      if (masterKotaIndex === -1) {
        var response = await lou.customUrlGet2('tabungan/kota')
        if (response) {
          var masterKota = {
            mykey:
              'masterKota' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
            data: JSON.stringify(response.data),
          }
          await lou.addDataToDb(masterKota)
          this.masterKota = this.$g.clone(response.data)
          this.backupmasterKota = this.$g.clone(response.data)
          // console.log('this.backupmasterKota', this.backupmasterKota)
        }
      } else {
        this.masterKota = JSON.parse(indexeddb[masterKotaIndex].data)
        this.backupmasterKota = JSON.parse(indexeddb[masterKotaIndex].data)
      }
    },
    async getMasterKecamatan() {
      var indexeddb = await lou.getDataFromDb()
      var masterKecamatanIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'masterKecamatan' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      if (masterKecamatanIndex === -1) {
        var response = await lou.customUrlGet2('tabungan/kecamatan')
        if (response) {
          var masterKecamatan = {
            mykey:
              'masterKecamatan' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
            data: JSON.stringify(response.data),
          }
          await lou.addDataToDb(masterKecamatan)
          if (this.action === 'update') {
            this.masterKecamatan = this.$g.clone(response.data)
          }
          this.backupmasterKecamatan = this.$g.clone(response.data)
          // console.log('this.backupmasterKecamatan', this.backupmasterKecamatan)
        }
      } else {
        if (this.action === 'update') {
          this.masterKecamatan = JSON.parse(indexeddb[masterKecamatanIndex].data)
        }
        this.backupmasterKecamatan = JSON.parse(indexeddb[masterKecamatanIndex].data)
      }
    },
    async getMasterKelurahan() {
      var indexeddb = await lou.getDataFromDb()
      var masterKelurahanIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'masterKelurahan' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      if (masterKelurahanIndex === -1) {
        var response = await lou.customUrlGet2('tabungan/kelurahan')
        if (response) {
          var masterKelurahan = {
            mykey:
              'masterKelurahan' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
            data: JSON.stringify(response.data),
          }
          await lou.addDataToDb(masterKelurahan)
          if (this.action === 'update') {
            this.masterKelurahan = this.$g.clone(response.data)
          }
          this.backupmasterKelurahan = this.$g.clone(response.data)
          // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
        }
      } else {
        if (this.action === 'update') {
          this.masterKelurahan = JSON.parse(indexeddb[masterKelurahanIndex].data)
        }
        this.backupmasterKelurahan = JSON.parse(indexeddb[masterKelurahanIndex].data)
      }
      if (this.action === 'update') {
        // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
        var findTargetKelurahan = this.backupmasterKelurahan.findIndex(x => x.subdis_id === this.editdata.kelurahan)
        // console.log('findTargetKelurahan', findTargetKelurahan)
        if (findTargetKelurahan !== -1) {
          var data = this.backupmasterKelurahan[findTargetKelurahan]
          // console.log('data', data)
          this.maskInput.kota = data.city_id
          this.maskInput.kecamatan = data.dis_id
        }
      }
      this.loadingMaster = false
    },
    async handleSubmit() {
      // Should format date value before submit.
      var res = false
      var fd = this.$g.clone(this.input)
      // console.log('fd', fd)
      fd.tgl =
        this.input.tgl !== null
          ? this.input.tgl.format('YYYY-MM-DD')
          : null
      if (this.action === 'update') {
        res = await lou.customUrlPut2('tabungan', fd, false)
      } else {
        delete fd.id
        res = await lou.customUrlPost2('tabungan', fd, false)
      }
      if (res) {
        lou.shownotif('Success', 'Data baru Berhasil ditambahkan dengan nomor rekening: ' + res.data, 'success')
        this.resetForm()
      }
    },
    getEditData() {
      console.log('this.editdata', this.editdata)
      // var res = await lou.customUrlGet2('tabungan', { where: { id: this.editdata.id } })
      this.input = this.$g.clone(this.editdata)
      this.maskInput.cabang = this.editdata.register.slice(0, this.editdata.register.indexOf('.'))
      this.maskInput.reregister = this.editdata.register.slice(this.editdata.register.indexOf('.') + 1, this.editdata.register.length)
      this.maskInput.namanasabah = this.editdata.nama
      this.maskInput.alamatnasabah = this.editdata.alamat
      this.maskInput.telepon = this.editdata.hp
      this.input.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD')
      var text = this.editdata.rekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(text.indexOf('.') + 1, text.indexOf('.') + 3)
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(ntext.indexOf('.') + 1, ntext.length)
      // console.log('ntext', ntext)
      // console.log('this.editdata', this.editdata)
    },
    resetForm() {
      localStorage.removeItem('newkoperasiedittabungandata')
      this.input = {
        rekening: '',
        tgl: moment(),
        golongantabungan: '',
        golongan_id: 0,
        register: '',
        register_id: 0,
        zis: 0,
        ao: '',
        ahliwaris: '',
        noseri: '',
        baristerakhir: 0,
      }
      this.$router.push('/koperasi/tabungan/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan += this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan += this.inputModal.alamat !== '' ? this.inputModal.nama !== '' ? '&Alamat=' + this.inputModal.alamat : '?Alamat=' + this.inputModal.alamat : ''
      imbuhan += this.inputModal.cabang !== '' ? '&cabang=' + this.inputModal.cabang : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(record.kode.indexOf('.') + 1, record.kode.length)
        this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
